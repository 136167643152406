import config from "../../config";
const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};
export const emp_login = async (Mobile, Password) => {
  const response = await fetch(config.API_URL + "Visionet/VISIONet_Emp_login", {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      Mobile: Mobile,
      Password: Password,
    }),
  });
  if (!response.ok) {
    throw new Error("Data coud not be fetched!");
  } else {
    return response.json();
  }
};
export const licenceList = async () => {
  const response = await fetch(
    config.API_URL + "Visionet/VISIONet_Licence_Key_List"
  );
  if (!response.ok) {
    throw new Error("Data coud not be fetched!");
  } else {
    return response.json();
  }
};
export const empList = async () => {
  const response = await fetch(config.API_URL + "Visionet/VISIONet_Emp_List");
  if (!response.ok) {
    throw new Error("Data coud not be fetched!");
  } else {
    return response.json();
  }
};
export const fetchTrans = async () => {
  const response = await fetch(
    config.API_URL + "Visionet/Tilak_Transactions_List"
  );
  if (!response.ok) {
    throw new Error("Data coud not be fetched!");
  } else {
    return response.json();
  }
};
export const licence_ins = async (
  ProductName,
  OrgName,
  CityName,
  StateName,
  GeneratedBy,
  DongleID,
  UserCount,
  ValidityDuration
) => {
  const response = await fetch(
    config.API_URL + "Visionet/VISIONet_Licence_Key_Ins",
    {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        ProductName: ProductName,
        OrgName: OrgName,
        CityName: CityName,
        StateName: StateName,
        GeneratedBy: GeneratedBy,
        DongleID: DongleID,
        UserCount: UserCount,
        ValidityDuration: ValidityDuration,
      }),
    }
  );
  if (!response.ok) {
    throw new Error("Data coud not be fetched!");
  } else {
    return response.json();
  }
};
export const emp_ins = async (EmpName, Mobile, Email, Address, Gender) => {
  const response = await fetch(config.API_URL + "Visionet/VISIONet_Emp_Ins", {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      EmpName: EmpName,
      Mobile: Mobile,
      Email: Email,
      Address: Address,
      Gender: Gender,
    }),
  });
  if (!response.ok) {
    throw new Error("Data coud not be fetched!");
  } else {
    return response.json();
  }
};
export const customers_upd = async (
  CustomerID,
  CustomerName,
  Mobile,
  CustomerAddress,
  Email,
  Remarks
) => {
  const response = await fetch(
    config.API_URL + "TilakTajpur/Tilak_Customers_Ins",
    {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        CustomerID: CustomerID,
        CustomerName: CustomerName,
        Mobile: Mobile,
        CustomerAddress: CustomerAddress,
        Email: Email,
        Remarks: Remarks,
      }),
    }
  );
  if (!response.ok) {
    throw new Error("Data coud not be fetched!");
  } else {
    return response.json();
  }
};
export const trans_ins = async (
  CustomerID,
  TranDate,
  TranType,
  TranAmt,
  TranRate,
  Remarks
) => {
  const response = await fetch(
    config.API_URL + "TilakTajpur/Tilak_CustomerTrans_Ins",
    {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        CustomerID: CustomerID,
        TranDate: TranDate,
        TranType: TranType,
        TranAmt: TranAmt,
        TranRate: TranRate,
        Remarks: Remarks,
      }),
    }
  );
  if (!response.ok) {
    throw new Error("Data coud not be fetched!");
  } else {
    return response.json();
  }
};
export const trans_del = async (TranID) => {
  const response = await fetch(
    config.API_URL + "TilakTajpur/Tilak_CustomerTrans_Ins",
    {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        TranID: TranID,
      }),
    }
  );
  if (!response.ok) {
    throw new Error("Data coud not be fetched!");
  } else {
    return response.json();
  }
};
