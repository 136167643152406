import React from "react";
import { Switch, Route, withRouter } from "react-router";

import LicenceList from "./LicenceList";
import LicenceNew from "./LicenceNew";

const Licences = () => {
  return (
    <Switch>
      <Route path="/app/licences" exact component={LicenceList} />
      <Route path="/app/licences/new" exact component={LicenceNew} />
    </Switch>
  );
};

export default withRouter(Licences);
