import { connect } from "react-redux";
import cx from "classnames";
import React, { useState,useEffect } from "react";
import PropTypes from "prop-types";
import {
  Navbar,
  Nav,
  NavItem,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { NavLink, useHistory } from "react-router-dom";

import Icon from "../Icon";

import photo from "../../images/photo.jpg";
import { logoutUser } from "../../actions/user";
import s from "./Header.module.scss";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [loggedInUserName,setLoggedInUserName]=useState('');
  const history = useHistory();
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    // setIsOpen((prevState) => ({
    //   isOpen: !prevState.isOpen,
    // }));
  };
  const fetch_session_status=()=>{
    if(sessionStorage.getItem('session')==null || sessionStorage.getItem('session')==undefined || sessionStorage.getItem('session')==''){
      //history.push("../../");
    } else {
      var userInfo=JSON.parse(sessionStorage.getItem('session'));
      //console.log(userInfo);
      setLoggedInUserName(userInfo.EmpName);
      //console.log('Header:'+loggedInUserName +" : "+userInfo.EmpName);
    }
  }
  useEffect(() => {
    fetch_session_status();
    
  }, []);
  const doLogout = () => {
    //this.props.dispatch(logoutUser());
    sessionStorage.removeItem('session');
    history.replace("/");
  };
  const sidebarToggle = () => {};
  return (
    <Navbar className={s.root}>
      <Nav>
        <NavItem
          className={cx(
            "visible-xs mr-4 d-sm-up-none",
            s.headerIcon,
            s.sidebarToggler
          )}
          href="#"
          onClick={sidebarToggle}
        >
          <i className="fa fa-bars fa-2x text-muted" />
        </NavItem>
        <NavItem>
          <InputGroup>
            <Input placeholder="Search for..." />
            <InputGroupAddon addonType="append" className="px-2">
              <i className="fa fa-search" />
            </InputGroupAddon>
          </InputGroup>
        </NavItem>
      </Nav>
      <Nav className="ml-auto">
        <NavItem className={cx("", s.headerIcon)}>
          <Button>
            <Icon glyph="mail" />
            <span>8</span>
          </Button>
        </NavItem>
        <NavItem className={cx("", s.headerIcon)}>
          <Button>
            <Icon glyph="notification" />
            <span>13</span>
          </Button>
        </NavItem>
        <NavItem className={cx("", s.headerIcon)}>
          <Button>
            <Icon glyph="settings" />
          </Button>
        </NavItem>
        <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
          <DropdownToggle nav>
            <img
              className={cx("rounded-circle mr-sm", s.adminPhoto)}
              src={photo}
              alt={loggedInUserName}
            />
            <span className="text-body">{loggedInUserName}</span>
            <i
              className={cx("fa fa-angle-down ml-sm", s.arrow, {
                [s.arrowActive]: isOpen,
              })}
            />
          </DropdownToggle>
          <DropdownMenu style={{ width: "100%" }}>
            <DropdownItem>
              <NavLink to="/app/posts">Posts</NavLink>
            </DropdownItem>
            <DropdownItem>
              <NavLink to="/app/profile">Profile</NavLink>
            </DropdownItem>
            <DropdownItem onClick={doLogout}>Logout</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </Nav>
    </Navbar>
  );
};

// function mapStateToProps(state) {
//   return {
//     init: state.runtime.initialNow,
//   };
// }
export default Header; //connect(mapStateToProps)(Header);
