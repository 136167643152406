import components from "./components.svg";
import dashboard from "./dashboard.svg";
import notifications from "./notifications.svg";
import typography from "./typography.svg";
import tables from "./tables.svg";
import logo from "./logo.svg";
import settings from "./settings.svg";
import notification from "./notification.svg";
import mail from "./mail.svg";
import user from "./user.svg";
import key from "./key.svg";

export default {
  components,
  dashboard,
  notifications,
  typography,
  tables,
  logo,
  settings,
  notification,
  mail,
  user,
  key,
};
