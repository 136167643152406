import React from "react";
import { Switch, Route, withRouter } from "react-router";

import UserList from "./UserList";
import UserNew from "./UserNew";

const Users = () => {
  return (
    <Switch>
      <Route path="/app/users" exact component={UserList} />
      <Route path="/app/users/new" exact component={UserNew} />
    </Switch>
  );
};

export default withRouter(Users);
