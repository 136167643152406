import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  ButtonGroup,
  Alert,
  Label,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import { connect } from "react-redux";

import Widget from "../../components/Widget";

import { emp_ins } from "../../components/apis/visionet_api";
import s from "./UserNew.module.scss";
const UserNew = () => {
  const [empName, setEmpName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("Male");
  const [address, setAddress] = useState("");

  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const history = useHistory();
  const navigateBack = () => {
    history.push("../users");
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setIsFetching(true);
    setIsError(false);
    setErrorMessage("");
    emp_ins(empName, mobile, email, address, gender)
      .then((data) => {
        console.log(data);
        setIsFetching(false);
        if (data.success == false) {
          setIsError(true);
          setErrorMessage(data.error.message);
        } else history.push("../users");
      })
      .catch((e) => {
        console.log(e.message);
      });
  };
  return (
    <div className={s.root}>
      <Breadcrumb>
        <BreadcrumbItem>Home</BreadcrumbItem>
        <BreadcrumbItem active>New User</BreadcrumbItem>
      </Breadcrumb>
      <h1>Add new user</h1>
      <Row>
        <Col sm={12} md={6} lg={6} xs={12}>
          <Widget
          // title={
          //   <span>
          //     Add User <span className="fw-semi-bold">Form</span>
          //   </span>
          // }
          >
            <Form onSubmit={submitHandler}>
              {isError && (
                <Alert className="alert-sm" bsstyle="info">
                  {errorMessage}
                </Alert>
              )}
              <Row>
                <Col sm={12} md={6} lg={6} xs={12}>
                  <FormGroup>
                    <Label for="input-name">Name</Label>
                    <Input
                      id="input-name"
                      type="text"
                      placeholder="Name"
                      required
                      onChange={(ev) => {
                        setEmpName(ev.target.value);
                      }}
                      value={empName}
                    />
                  </FormGroup>
                </Col>
                <Col sm={12} md={6} lg={6} xs={12}>
                  <FormGroup>
                    <Label for="input-mobile">Mobile Number</Label>
                    <Input
                      id="input-mobile"
                      type="text"
                      className="form-control"
                      placeholder="Mobile Number"
                      required
                      onChange={(ev) => {
                        setMobile(ev.target.value);
                      }}
                      value={mobile}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label for="input-address">Address</Label>
                <Input
                  id="input-address"
                  type="text"
                  className="form-control"
                  placeholder="Address"
                  required
                  onChange={(ev) => {
                    setAddress(ev.target.value);
                  }}
                  value={address}
                />
              </FormGroup>

              <Row>
                <Col sm={12} md={6} lg={6} xs={12}>
                  <FormGroup>
                    <Label for="input-email">Email</Label>
                    <Input
                      id="input-email"
                      type="text"
                      className="form-control"
                      placeholder="Email"
                      required
                      onChange={(ev) => {
                        setEmail(ev.target.value);
                      }}
                      value={email}
                    />
                  </FormGroup>
                </Col>
                <Col sm={12} md={6} lg={6} xs={12}>
                  <FormGroup>
                    <Label for="select-gender">Gender</Label>
                    <br />
                    <select
                      defaultValue="LL"
                      className="form-control"
                      id="select-gender"
                      onChange={(e) => {
                        setGender(e.target.value);
                      }}
                      value={gender}
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6} lg={6} xs={12}></Col>
                <Col sm={12} md={6} lg={6} xs={12}>
                  <FormGroup>
                    <Label>
                      <br />
                    </Label>
                    <div className="d-flex justify-content-end">
                      <ButtonGroup>
                        <Button color="success" type="submit">
                          {isFetching ? "Submitting..." : "Submit"}
                        </Button>
                        <Button color="danger" onClick={navigateBack}>
                          Cancel
                        </Button>
                      </ButtonGroup>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Widget>
        </Col>
      </Row>
    </div>
  );
};
export default UserNew;
