import React, { useState,useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  ButtonGroup,
  Alert,
  Label,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import { connect } from "react-redux";

import Widget from "../../components/Widget";

import { licence_ins } from "../../components/apis/visionet_api";
import s from "./LicenceNew.module.scss";
const LicenceNew = () => {
  const [productName, setProductName] = useState("LL");
  const [orgName, setOrgName] = useState("");
  const [cityName, setCityName] = useState("");
  const [stateName, setStateName] = useState("");
  const [generatedBy, setGeneratedBy] = useState(1);

  const [dongleID, setDongleID] = useState("");
  const [userCount, setUserCount] = useState("");
  const [validityDuration, setValidityDuration] = useState("");

  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const history = useHistory();
  const navigateBack = () => {
    history.push("../licences");
  };
  useEffect(() => {
    var userInfo=JSON.parse(sessionStorage.getItem('session'));
    setGeneratedBy(userInfo.EmpID);
  }, []);
  const submitHandler = (e) => {
    e.preventDefault();
    setIsFetching(true);
    setIsError(false);
    setErrorMessage("");
    licence_ins(
      productName,
      orgName,
      cityName,
      stateName,
      generatedBy,
      dongleID,
      userCount,
      validityDuration
    )
      .then((data) => {
        console.log(data);
        setIsFetching(false);
        if (data.success == false) {
          setIsError(true);
          setErrorMessage(data.error.message);
        } else history.push("../licences");
      })
      .catch((e) => {
        console.log(e.message);
      });
  };
  const productChangeHandler = (e) => {
    setProductName(e.target.value);
  };
  return (
    <div className={s.root}>
      <Breadcrumb>
        <BreadcrumbItem>Home</BreadcrumbItem>
        <BreadcrumbItem active>New Licence</BreadcrumbItem>
      </Breadcrumb>
      <h1>Generate new licence</h1>
      <Row>
        <Col sm={12} md={6} lg={6} xs={12}>
          <Widget
            title={
              <span>
                Add Licence <span className="fw-semi-bold">Form</span>
              </span>
            }
          >
            <Form onSubmit={submitHandler}>
              {isError && (
                <Alert className="alert-sm" bsstyle="info">
                  {errorMessage}
                </Alert>
              )}
              <Row>
                <Col sm={6} md={3} lg={3} xs={6}>
                  <FormGroup>
                    <Label for="select-product-name">Product Name</Label>
                    <br />
                    <select
                      defaultValue="LL"
                      className="form-control"
                      id="select-product-name"
                      onChange={(e) => {
                        setProductName(e.target.value);
                      }}
                      value={productName}
                    >
                      <option value="LL">LL</option>
                      <option value="LMS">LMS</option>
                    </select>
                  </FormGroup>
                </Col>
                <Col sm={12} md={6} lg={6} xs={12}>
                  <FormGroup>
                    <Label for="input-title">Dongle ID</Label>
                    <Input
                      id="input-title"
                      type="text"
                      placeholder="Dongle ID"
                      required
                      onChange={(ev) => {
                        setDongleID(ev.target.value);
                      }}
                      value={dongleID}
                    />
                  </FormGroup>
                </Col>
                <Col sm={6} md={3} lg={3} xs={6}>
                  <FormGroup>
                    <Label for="input-user-count">User Count</Label>
                    <Input
                      id="input-user-count"
                      type="number"
                      className="form-control"
                      placeholder="User Count"
                      required
                      onChange={(ev) => {
                        setUserCount(ev.target.value);
                      }}
                      value={userCount}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup>
                <Label for="input-org-name">Organization</Label>
                <Input
                  id="input-org-name"
                  type="text"
                  className="form-control"
                  placeholder="Organization Name"
                  required
                  onChange={(ev) => {
                    setOrgName(ev.target.value);
                  }}
                  value={orgName}
                />
              </FormGroup>
              <Row>
                <Col sm={12} md={6} lg={6} xs={12}>
                  <FormGroup>
                    <Label for="input-city-name">City</Label>
                    <Input
                      id="input-city-name"
                      type="text"
                      className="form-control"
                      placeholder="City Name"
                      required
                      onChange={(ev) => {
                        setCityName(ev.target.value);
                      }}
                      value={cityName}
                    />
                  </FormGroup>
                </Col>
                <Col sm={12} md={6} lg={6} xs={12}>
                  <FormGroup>
                    <Label for="input-state-name">State</Label>
                    <Input
                      id="input-state-name"
                      type="text"
                      className="form-control"
                      placeholder="State Name"
                      required
                      onChange={(ev) => {
                        setStateName(ev.target.value);
                      }}
                      value={stateName}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6} lg={6} xs={12}>
                  <FormGroup>
                    <Label for="input-validity-duration">
                      Validity Duration (in Days)
                    </Label>
                    <Input
                      id="input-validity-duration"
                      type="number"
                      className="form-control"
                      placeholder="Duration"
                      required
                      onChange={(ev) => {
                        setValidityDuration(ev.target.value);
                      }}
                      value={validityDuration}
                    />
                  </FormGroup>
                </Col>
                <Col sm={12} md={6} lg={6} xs={12}>
                  <FormGroup>
                    <Label for="input-validity-duration">
                      <br />
                    </Label>
                    <div className="d-flex justify-content-start">
                      <ButtonGroup>
                        <Button color="success" type="submit">
                          {isFetching ? "Generating..." : "Generate"}
                        </Button>
                        <Button color="danger" onClick={navigateBack}>
                          Cancel
                        </Button>
                      </ButtonGroup>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Widget>
        </Col>
      </Row>
    </div>
  );
};
export default LicenceNew;
