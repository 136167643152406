import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect, useHistory } from "react-router-dom";
//import { connect } from 'react-redux';
import { Alert, Button, FormGroup, Input, Row, Col, Form } from "reactstrap";
import s from "./Login.module.scss";
import Widget from "../../components/Widget";
import Footer from "../../components/Footer";
import { loginUser } from "../../actions/user";
import jwt from "jsonwebtoken";
import config from "../../config";
import { emp_login } from "../../components/apis/visionet_api";

const Login = () => {
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const history = useHistory();

  const loginHandler = (e) => {
    e.preventDefault();
    sessionStorage.removeItem('session');
    setIsFetching(true);
    setIsError(false);
    setErrorMessage("");
    emp_login(mobile, password)
      .then((data) => {
        //console.log(data);
        setIsFetching(false);
        if (data.success == false) {
          setIsError(true);
          setErrorMessage(data.error.message);
        } else {
          sessionStorage.setItem('session',JSON.stringify(data.payload));
          history.push("./app");
      }
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  return (
    <div className={s.root}>
      <Row>
        <Col
          xs={{ size: 10, offset: 1 }}
          sm={{ size: 6, offset: 3 }}
          lg={{ size: 4, offset: 4 }}
        >
          <p className="text-center">VISIONet Login</p>
          <Widget className={s.widget}>
            <h4 className="mt-0">Login to your Web App</h4>
            <p className="fs-sm text-muted">
              User your username and password to sign in
              <br />
              Don&#39;t have an account? Sign up now!
            </p>
            <Form className="mt" onSubmit={loginHandler}>
              {isError && (
                <Alert size="sm" color="danger">
                  {errorMessage}
                </Alert>
              )}
              <FormGroup className="form-group">
                <Input
                  className="no-border"
                  type="text"
                  required
                  name="username"
                  placeholder="Username"
                  onChange={(ev) => {
                    setMobile(ev.target.value);
                  }}
                  value={mobile}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  className="no-border"
                  type="password"
                  required
                  name="password"
                  placeholder="Password"
                  onChange={(ev) => {
                    setPassword(ev.target.value);
                  }}
                  value={password}
                />
              </FormGroup>
              <div className="d-flex justify-content-between align-items-center">
                <a href="#" className="fs-sm">
                  Forgot password?
                </a>{" "}
                {/* eslint-disable-line */}
                <div>
                  <Button color="default" size="sm">
                    Create an account
                  </Button>
                  <Button color="success" size="sm" type="submit">
                    {isFetching ? "Loading..." : "Login"}
                  </Button>
                </div>
              </div>
            </Form>
          </Widget>
        </Col>
      </Row>
      <Footer className="text-center" />
    </div>
  );
};

// function mapStateToProps(state) {
//     return {
//         isFetching: state.auth.isFetching,
//         isAuthenticated: state.auth.isAuthenticated,
//         errorMessage: state.auth.errorMessage,
//     };
// }

export default Login; //withRouter(connect(mapStateToProps)(Login));
