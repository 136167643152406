import React,{useEffect,useState} from "react";
import cx from "classnames";
import { Switch, Route, withRouter } from "react-router";
import { useHistory } from 'react-router-dom';
import s from "./Layout.module.scss";
import Header from "../Header";
import Footer from "../Footer";
import Sidebar from "../Sidebar";

// Dashboard component is loaded directly as an example of server side rendering
import Dashboard from "../../pages/dashboard";
import Buttons from "../../pages/buttons";
import Charts from "../../pages/charts";
import Maps from "../../pages/google";
import NotFound from "../../pages/notFound";
import Icons from "../../pages/icons";
import Typography from "../../pages/typography";
import Tables from "../../pages/tables";
import Notifications from "../../pages/notifications";
import Posts from "../../pages/posts";
import Profile from "../../pages/profile";
import Privacy from "../../pages/privacy";
import About from "../../pages/about/About";
import Licences from "../../pages/licences/Licences";
import Users from "../../pages/users/Users";
const Layout=()=>{
    const [sidebarOpen, setSidebarOpen]=useState(false);
    const history = useHistory();
    const fetch_session_status=()=>{
        if(sessionStorage.getItem('session')==null || sessionStorage.getItem('session')==undefined || sessionStorage.getItem('session')==''){
            history.push("../");
        } else {
            //var userInfo=JSON.parse(sessionStorage.getItem('session'));
        }
    }
    useEffect(() => {
      fetch_session_status();
    }, []);
    return (
        <div className={s.root}>
          <Sidebar />
          <div
            className={cx(s.wrap, { [s.sidebarOpen]: sidebarOpen })}
          >
            <Header
              sidebarToggle={() =>
                this.setState({
                  sidebarOpen: !sidebarOpen,
                })
              }
            />
            <main className={s.content}>
              <Switch>
                <Route path="/app/main" exact component={Dashboard} />
                <Route path="/app/typography" exact component={Typography} />
                <Route path="/app/tables" exact component={Tables} />
                <Route path="/app/about" exact component={About} />
                <Route path="/app/licences" component={Licences} />
                <Route path="/app/users" component={Users} />
                <Route path="/app/posts" component={Posts} />
                <Route path="/app/privacy" exact component={Privacy} />
                <Route path="/app/profile" exact component={Profile} />
                <Route
                  path="/app/notifications"
                  exact
                  component={Notifications}
                />
                <Route path="/app/components/buttons" exact component={Buttons} />
                <Route path="/app/components/charts" exact component={Charts} />
                <Route path="/app/components/icons" exact component={Icons} />
                <Route path="/app/components/maps" exact component={Maps} />
                <Route component={NotFound} />
              </Switch>
            </main>
            <Footer />
          </div>
        </div>
      );
}
export default Layout;