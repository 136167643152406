import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Table, Breadcrumb, BreadcrumbItem } from "reactstrap";

import s from "./UserList.module.scss";
import Widget from "../../components/Widget";
import { empList } from "../../components/apis/visionet_api";

const UserList = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const DATE_OPTIONS = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const fetch_users = () => {
    setIsFetching(true);
    setIsError(false);
    setErrorMessage("");
    empList()
      .then((data) => {
        console.log(data);
        setIsFetching(false);
        if (data.success == false) {
          setIsError(true);
          setErrorMessage(data.error.message);
        } else {
          setUsers(data.payload);
          setAllUsers(data.payload);
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  };
  useEffect(() => {
    fetch_users();
  }, []);
  const filterUsers = (e) => {
    var sText = e.target.value.toLowerCase();
    var temp_lic = allUsers;
    if (sText != "")
      temp_lic = allUsers.filter(
        (lic) =>
          lic.EmpName.toLowerCase().indexOf(sText) >= 0 ||
          lic.Mobile.indexOf(sText) >= 0 ||
          lic.Email.toLowerCase().indexOf(sText) >= 0
      );
    setUsers(temp_lic);
  };
  return (
    <div className={s.root}>
      <Breadcrumb>
        <BreadcrumbItem>Home</BreadcrumbItem>
        <BreadcrumbItem active>Users</BreadcrumbItem>
      </Breadcrumb>
      <h1>Users</h1>
      <Widget
        className="pb-0"
        title={
          <div>
            <div className="pull-right mt-n-xs">
              <Link to="/app/users/new" className="btn btn-sm btn-inverse">
                Create new
              </Link>
            </div>
            <div className="pull-right mt-n-xs">
              <input
                type="text"
                className="form-control input-sm"
                placeholder="Search..."
                onChange={filterUsers}
              />
              {/* <Link to="/app/users/new" className="btn btn-sm btn-inverse">
                Create new
              </Link> */}
            </div>
            <h5 className="mt-0">
              Users <span className="fw-semi-bold">List</span>
            </h5>
          </div>
        }
      >
        <div className="widget-table-overflow">
          <Table striped className={s.mainTable}>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Mobile </th>
                <th>Email</th>
                <th>Date</th>
                <th>status</th>
              </tr>
            </thead>
            <tbody>
              {users &&
                users.map((user, index) => (
                  <tr key={user.EmpID}>
                    <td>{index + 1}</td>
                    <td>{user.EmpName}</td>
                    <td>{user.Mobile}</td>
                    <td>{user.Email}</td>
                    <td>
                      {new Date(user.EntryDate).toLocaleDateString(
                        "en-IN",
                        DATE_OPTIONS
                      )}
                    </td>
                    <td>
                      {user.IsActive == 1 ? (
                        <span class="py-0 px-1 bg-success rounded text-white">
                          active
                        </span>
                      ) : (
                        <span class="py-0 px-1 bg-danger rounded text-white">
                          in-active
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              {isFetching && (
                <tr>
                  <td colSpan="100">Loading...</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Widget>
    </div>
  );
};

export default UserList;
