import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Table, Breadcrumb, BreadcrumbItem } from "reactstrap";

import s from "./LicenceList.module.scss";
import Widget from "../../components/Widget";
import { licenceList } from "../../components/apis/visionet_api";

const LicenceList = () => {
  const [allLicences, setAllLicences] = useState([]);
  const [licences, setLicences] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchKey, setSearchKey] = useState("");

  const fetch_licences = () => {
    setIsFetching(true);
    setIsError(false);
    setErrorMessage("");
    licenceList()
      .then((data) => {
        console.log(data);
        setIsFetching(false);
        if (data.success == false) {
          setIsError(true);
          setErrorMessage(data.error.message);
        } else {
          setLicences(data.payload);
          setAllLicences(data.payload);
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  };
  useEffect(() => {
    fetch_licences();
  }, []);
  const filterLicences = (e) => {
    var sText = e.target.value.toLowerCase();
    var temp_lic = allLicences;
    if (sText != "")
      temp_lic = allLicences.filter(
        (lic) =>
          lic.CityName.toLowerCase().indexOf(sText) >= 0 ||
          lic.StateName.toLowerCase().indexOf(sText) >= 0 ||
          lic.OrgName.toLowerCase().indexOf(sText) >= 0 ||
          lic.ProductName.toLowerCase() == sText ||
          lic.DongleID.toLowerCase() == sText
      );
    setLicences(temp_lic);
  };
  return (
    <div className={s.root}>
      <Breadcrumb>
        <BreadcrumbItem>Home</BreadcrumbItem>
        <BreadcrumbItem active>Licences</BreadcrumbItem>
      </Breadcrumb>
      <h1>Licences</h1>
      <Widget
        className="pb-0"
        title={
          <div>
            <div className="pull-right mt-n-xs">
              <Link to="/app/licences/new" className="btn btn-sm btn-inverse">
                Create new
              </Link>
            </div>
            <div className="pull-right mt-n-xs">
              <input
                type="text"
                className="form-control input-sm"
                placeholder="Search..."
                onChange={filterLicences}
              />
              {/* <Link to="/app/licences/new" className="btn btn-sm btn-inverse">
                Create new
              </Link> */}
            </div>
            <h5 className="mt-0">
              Licences <span className="fw-semi-bold">List</span>
            </h5>
          </div>
        }
      >
        <div className="widget-table-overflow">
          <Table striped className={s.mainTable}>
            <thead>
              <tr>
                <th>Product</th>
                <th>Dongle ID [Users]</th>
                <th>Organization</th>
                <th>Licence</th>
              </tr>
            </thead>
            <tbody>
              {licences &&
                licences.map((licence) => (
                  <tr key={licence.LicenceID}>
                    <td>{licence.ProductName}</td>
                    <td>
                      {licence.DongleID} [{licence.UserCount}]
                    </td>
                    <td>
                      {licence.OrgName}, {licence.CityName}, {licence.StateName}
                    </td>
                    <td>{licence.LicenceCode}</td>
                  </tr>
                ))}
              {isFetching && (
                <tr>
                  <td colSpan="100">Loading...</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Widget>
    </div>
  );
};

export default LicenceList;
